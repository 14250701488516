/* eslint
    @typescript-eslint/no-unused-vars: "off",
    @typescript-eslint/restrict-template-expressions: "off"
*/

import dialogPolyfill from 'dialog-polyfill'


// -------------------------------------------------------------------------------------
// Modals and Dialogs
// -------------------------------------------------------------------------------------
interface setupModalsInterface {
  formResetCallbacks?: CallableFunction[]
  formInitials?: { [key: string]: string | boolean }
}

function setupModals (options?: setupModalsInterface): void {
  const formResetCallbacks = options?.formResetCallbacks
  const formInitials = options?.formInitials
  const modalLinks: NodeListOf<HTMLElement> = document.querySelectorAll('[data-open-modal]')

  modalLinks.forEach(function (link) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const dialogLink: string = link.getAttribute('data-open-modal')!
    const matchingDialog: HTMLDialogElement | null = document.getElementById(dialogLink) as HTMLDialogElement


    link.addEventListener('click', openThisDialog)

    function openThisDialog (e: Event): void {
      if (matchingDialog === null) {
        return
      }

      // Discard any other form interaction that might be bound to the modal-open
      // button.
      e.preventDefault()

      dialogPolyfill.registerDialog(matchingDialog)

      //  If a dialog is being called from another dialog, close the first one.
      const anyOpenDialog: HTMLDialogElement | null = document.querySelector('dialog[open]')
      if (anyOpenDialog != null) {
        dialogPolyfill.registerDialog(anyOpenDialog)
        anyOpenDialog.close()
      }

      // Don't allow submitting the form via "Enter" from an input field
      // inside the modal. We likely either submit the form in an invalid state,
      // or close the modal, or open another modal, depending on what the next 'submit'
      // button is.
      const dialogInputFields = matchingDialog.querySelectorAll('input')
      dialogInputFields.forEach((field: HTMLInputElement) => {
        field.addEventListener('keypress', function (e: KeyboardEvent) {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        })
      })

      // Show the Modal
      matchingDialog.showModal()

      // Clicking the modal close button will close the modal 👨‍🔬
      matchingDialog.querySelectorAll('[data-close-button]').forEach((btn: any) => {
        btn.addEventListener('click', () => matchingDialog.close())
      })

      // A dialog may contain form fields that alters the current page, however closing
      // the dialog might lead to unexpected behavior, as we have values recalculated
      // with data that is not yet saved. To prevent that, reset all dialog form fields
      // when the dialog is closed. It's expected that the dialog has its own Save
      // button.
      matchingDialog.onclose = function () {
        // If there are no form initials provided, we don't have to do anything in
        // first place, since there's no data we can roll back to.
        if (formInitials == null) {
          return
        }

        const dialogInputFields: NodeListOf<HTMLInputElement> = matchingDialog.querySelectorAll('input')
        dialogInputFields.forEach((f) => {
          const originalValue = formInitials[f.id]
          console.debug(`Reset field value of ${f.name} to ${originalValue}`)
          if (f.type === 'checkbox') {
            f.checked = originalValue === true
          } else {
            f.value = originalValue.toString()
          }

          // Some forms may need to get recalculated since this is a form change.
          // We do this after every individual field change, that's a bit inefficient
          // but the same behavior as the regular 'calculate' form code.
          if (formResetCallbacks !== undefined) {
            formResetCallbacks.forEach(callback => {
              console.debug('Recalculating form', callback.name)
              callback()
            })
          }
        })
      }
    }
  })
}

// -------------------------------------------------------------------------------------
// Live Value Updates
// -------------------------------------------------------------------------------------

/**
 * Watch for keyboard events in a list of form fields and
 * call a callback function when any of them change.
 *
 * @param {CallableFunction} callback The function to call on every field change
 * @param {string} event The user event the callback is triggered (click, keypress, ...)
 * @param  {string[]} fields: List of query selectors for the fields to watch.
 */
interface WatchForEventInterface {
  callback: CallableFunction
  event: string
  fields: string[]
}

type SimplifiedWatchForEventInterface = Omit<WatchForEventInterface, 'event'>

function watchForEvent (options: WatchForEventInterface): void {
  // Run this once right away, so we have dataset attributes
  // for the target elements.
  options.callback()

  // But also run the callback whenever any of the listed fields does change
  for (const f of options.fields) {
    const field: HTMLInputElement | null = document.querySelector(f)
    if (field !== null) {
      field.addEventListener(options.event, () => options.callback())
    }
  }
}

function watchForKeyUp (options: SimplifiedWatchForEventInterface): void {
  watchForEvent({
    callback: options.callback,
    fields: options.fields,
    event: 'keyup'
  })
}

function watchForClick (options: SimplifiedWatchForEventInterface): void {
  watchForEvent({
    callback: options.callback,
    fields: options.fields,
    event: 'click'
  })
}

/**
 * Get the number value form a field. Returns null in case the field is empty
 * does not contain a number or is negative.
 */
function NumberField (query: string): number {
  const field: HTMLInputElement | null = document.querySelector(query)
  if (field === null) {
    throw Error(`Can not retrieve field. Element with query ${query} does not exist.`)
  }

  if (!field.validity.valid) {
    // If the field value is actually wrong and the field is not just
    // blank, mark the field with an error.
    if (field.validity.badInput) {
      field.classList.add('error')
    }
    return 0
  }

  field.classList.remove('error')
  return field.valueAsNumber
}

/**
 * Similar to the Numberfield function but checks for valid percentages,
 * and returns a quotient of the value divided by 100.
 */
function RateField (query: string): number {
  let value = NumberField(query)
  if (value !== undefined) {
    value = value / 100
  }
  return value
}

/**
 * Updates the innerHTML value of the given target element.
 * Returns the raw value without string prefix or suffix.
 *
 * @param {string} target The target element to update
 * @param {number} value Target Value
 * @param {string} prefix String Prefix (e.g. $)
 * @param {string} suffix String Suffix (e.g. %)
 * @param {boolean} intComma: Separate the thousands with <intCommaSymbol>.
 * @param {string} intCommaSymbol: The thousands separator (default: ,)
 * @returns {number} The rounded input value.
 */
interface ResultInterface {
  target: string
  value: number
  prefix?: string
  suffix?: string
  separateThousands?: boolean
  separateThousandsSymbol?: string
}

function Result (options: ResultInterface): number {
  const resultField: HTMLElement | null = document.querySelector(options.target)

  if (resultField === null) {
    throw Error(`Can not retrieve result field. Element with query ${options.target} does not exist.`)
  }

  const rawValue = Math.round(options.value)
  if (isNaN(rawValue)) {
    console.debug(`Unable to round value ${rawValue} to an integer.`)
    return 0
  }

  let strValue = rawValue.toString()
  if (options.separateThousands !== false) {
    strValue = strValue.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      options.separateThousandsSymbol ?? ','
    )
  }

  resultField.innerHTML = `${options.prefix ?? ''}${strValue}${options.suffix ?? ''}`
  resultField.dataset.value = rawValue.toString()

  if (rawValue < 0) {
    resultField.classList.add('result-error')
  } else {
    resultField.classList.remove('result-error')
  }

  return rawValue
}

/**
 * Try to get the raw value from the target element. Must be set as
 * a data-value attribute without any prefix or suffix.
 */
function TextValue (target: string): number {
  const t: HTMLElement | null = document.querySelector(target)

  if (t === null || t?.dataset?.value === undefined) {
    throw Error(`
      Can not retrieve text value. Element with query ${target} does not exist
      or is missing the data-value attribute.
    `)
  }

  return parseInt(t.dataset.value)
}

// -------------------------------------------------------------------------------------
// Testing Impact Features
// -------------------------------------------------------------------------------------

function checked (query: string): boolean {
  const field: HTMLInputElement | null = document.querySelector(query)
  if (field === null) {
    throw Error(`Checkbox field for query ${query} does not exist.`)
  }
  return field.checked
}
interface SetRatioLabelInterface {
  labelContainerQuery: string
  preventiveCheckboxQuery: string
  urinalysisCheckboxQuery: string
}

function setRatioLabel (options: SetRatioLabelInterface): void {
  const labelContainer = options.labelContainerQuery
  const preventive = options.preventiveCheckboxQuery
  const urinalysis = options.urinalysisCheckboxQuery

  // Find the associated labels and hide them initially
  const container: HTMLElement | null = document.querySelector(labelContainer)
  if (container === null) {
    throw Error(`Label container not found with query ${labelContainer}.`)
  }

  const labels: NodeListOf<HTMLElement> = container.querySelectorAll('.none, .just, .both')
  labels.forEach((l) => { l.style.display = 'none' })

  const urinalysisCheckbox: HTMLInputElement | null = document.querySelector(urinalysis)
  if (urinalysisCheckbox === null) {
    throw Error(`Urinalysis checkbox field missing in container ${labelContainer}.`)
  }

  // Preventive is not checked
  if (!checked(preventive)) {
    (container.querySelector('.none') as HTMLElement).style.display = 'block'

    // Unchecking preventive also unchecks urinalysis
    urinalysisCheckbox.checked = false
    urinalysisCheckbox.disabled = true

    // Both are checked
  } else if (checked(preventive) && checked(urinalysis)) {
    (container.querySelector('.both') as HTMLElement).style.display = 'block'
    urinalysisCheckbox.disabled = false

    // Just Preventive is checked
  } else {
    (container.querySelector('.just') as HTMLElement).style.display = 'block'
    urinalysisCheckbox.disabled = false
  }
}

// @ts-ignore
window.setupModals = setupModals
// @ts-ignore
window.watchForKeyUp = watchForKeyUp
// @ts-ignore
window.watchForClick = watchForClick
// @ts-ignore
window.watchForEvent = watchForEvent
// @ts-ignore
window.NumberField = NumberField
// @ts-ignore
window.RateField = RateField
// @ts-ignore
window.Result = Result
// @ts-ignore
window.TextValue = TextValue
// @ts-ignore
window.setRatioLabel = setRatioLabel

